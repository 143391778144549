<template>
  <div class="about">
    <div class="about-wrapper">
      <div class="about-banner"></div>
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>行业动态</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="about-newest" v-if="dataSheetList.length > 0">
          <div class="newest-left" v-if="newSheetList.length > 0">
            <div class="left-backimg">
              <img
                class="img"
                :src="newSheetList[0].picture ? newSheetList[0].picture : '../../assets/zlxcimgs/second_image_xinwentu@2x.png'"
              >
            </div>
            <div class="left-text" @click="tapJumpDetail(newSheetList[0].id)">
              <p class="tag">最新</p>
              <p class="text">{{newSheetList[0].title}}</p>
            </div>
          </div>
          <div class="newest-right" v-if="newSheetList.length > 0">
            <ul class="newest-list">
              <li class="list-li" v-for="item in newSheetList" :key="item.industryDynamicId" @click="tapJumpDetail(item.id)">
                <div class="li-text">
                  <p class="tag">最新</p>
                  <p class="text">{{item.title}}</p>
                </div>
                <p class="li-date">{{item.createDate}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="newest-lists">
          <div class="newest-li" v-for="item in dataSheetList" :key="item.industryDynamicId" @click="tapJumpDetail(item.id)">
            <div class="newest-center">
              <p class="center-line"></p>
              <p class="center-text">{{item.title}}</p>
            </div>
            <div class="date" >{{ item.createDate }}</div>
          </div>
        </div>
        <div class="newest-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pageObj.total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { querySimpleIndustryDynamics } from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: 'About',
  data () {
    return {
      // 分页信息
      pageObj: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      // 行业动态列表
      dataSheetList: [],
      // 最新行业动态列表
      newSheetList: []
    }
  },
  created() {
    // 行业动态列表
    this.getQuerySimpleIndustryDynamics()
  },
  mounted() {},
  computed: {},
  methods: {
    // 行业动态列表 type: 1
    getQuerySimpleIndustryDynamics () {
      querySimpleIndustryDynamics({
        isPublish: 1,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        type: 1
      }).then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(ele => {
            ele.createDate = formatTime(ele.createTime)
          })
          
          if (this.pageObj.pageNo == 1&&res.data.list.length>3) {
            this.newSheetList = res.data.list.splice(0, 3)
            console.log('this.newSheetList')
            console.log(this.newSheetList)
          }
          this.dataSheetList = res.data.list
          this.pageObj.total = res.data.total
        }
      })
    },
    // 当前currentPage 改变时会触发
    handleCurrentChange (curPage) {
      this.pageObj.pageNo = curPage
      this.getQuerySimpleIndustryDynamics()
    },
    // 跳转详情页
    tapJumpDetail (id) {
      this.$router.push({
        name:'Details',
        query: {
          id,
          type: 'about'
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
